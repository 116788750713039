import { enUS } from 'date-fns/locale'

export const MAX_CHALLENGES = 6
export const ALERT_TIME_MS = 2000
export const LONG_ALERT_TIME_MS = 10000
export const REVEAL_TIME_MS = 350
export const WELCOME_INFO_MODAL_MS = 350
export const DISCOURAGE_INAPP_BROWSERS = true
export const ENABLE_MIGRATE_STATS = true
export const BLOWFISH_KEY = 'xcQUAHsik#Thq&LG*8es2DsZ$3bw^e'
export const BLOWFISH_IV = '#45XmF^w'
export const ENABLE_ARCHIVED_GAMES = true
export const DATE_LOCALE = enUS
export const START_DATE = 'April 4, 2022'

var weekly_cadence = [1, 1, 1, 1, 3]
var weekly_period = new Array(37).fill(weekly_cadence).flat()
var winter_break_week_2022 = [1, 1, 1, 1, 10]
var large_weekly_period = new Array(400).fill(weekly_cadence).flat()
export const CADENCE = [
    ...weekly_period, 
    ...winter_break_week_2022,
    ...large_weekly_period,
];
