export const WORDS = [
  'honey',
  'world',
  'horse',
  'oscar',
  'scene',
  'rocky',
  'shrek',
  'thunk',
  'shark',
  'beeee',
  'premo',
  'toons',
  'laugh',
  'poppy',
  'award',
  'funny',
  'races',
  'after',
  'crane',
  'tooth',
  'nurbs',
  'intel',
  'timmy',
  'audio',
  'bilby',
  'curse',
  'cross',
  'rhino',
  'dream',
  'badge',
  'snake',
  'toast',
  'jamie',
  'ogres',
  'chars',
  'river',
  'waldo',
  'party',
  'bunny',
  'gmail',
  'music',
  'chris',
  'boots',
  'moons',
  'gingy',
  'karma',
  'black',
  'jokes',
  'chair',
  'snail',
  'mintz',
  'troll',
  'bench',
  'movie',
  'train',
  'works',
  'olive',
  'envir',
  'fiona',
  'image',
  'httyd',
  'notes',
  'jiras',
  'china',
  'hedge',
  'cycle',
  'marty',
  'model',
  'pipex',
  'story',
  'media',
  'rider',
  'north',
  'pixar',
  'dance',
  'score',
  'jerry',
  'edits',
  'moses',
  'jonah',
  'farts',
  'robot',
  'short',
  'myers',
  'lucky',
  'frost',
  'night',
  'sword',
  'kitty',
  'zooey',
  'fairy',
  'punch',
  'lunch',
  'pitch',
  'turbo',
  'tulio',
  'disco',
  'sandy',
  'angie',
  'smith',
  'valka',
  'wizzy',
  'peppy',
  'snoop',
  'roddy',
  'tfayd',
  'wabac',
  'hammy',
  'lemur',
  'swamp',
  'eddie',
  // 6-letter round
  'format',
  'chairs',
  'hiccup',
  'gromit',
  'mantis',
  'hasbro',
  'oogway',
  'laurie',
  'beeeee',
  'margie',
  'riders',
  'hunter',
  'insect',
  'wizzie',
  'status',
  'images',
  'shader',
  'legend',
  'thread',
  'julien',
  'altivo',
  'hector',
  'mirror',
  'kilmer',
  'object',
  'murphy',
  'prince',
  'shorts',
  'pharoh',
  'dubois',
  'pandas',
  'wizard',
  'geffen',
  'wolves',
  'stoick',
  'sensei',
  'tzekel',
  'dorado',
  'benson',
  'quincy',
  'hedges',
  'zephyr',
  'cookie',
  'street',
  'nadder',
  'donkey',
  'rigged',
  'chrome',
  'trolls',
  'spirit',
  'onions',
  'badguy',
  'effect',
  'humpty',
  'action',
  'dwaskg',
  'kungfu',
  'sinbad',
  'merlin',
  'gobber',
  'subway',
  'movies',
  'viking',
  'lagoon',
  'editor',
  'minion',
  'melman',
  'justin',
  'cooper',
  'biggie',
  'harvey',
  'circus',
  'terror',
  'gerard',
  'videos',
  'pegleg',
  'astrid',
  'bosses',
  'lemurs',
  'miguel',
  'dragon',
  'github',
  'thrive',
  'vision',
  'swamps',
  'badger',
  'gloria',
  'create',
  'smoove',
  'camera',
  'recalc',
  'legsly',
  'steven',
  'aliens',
  'shadow',
  'thrash',
  'family',
  'ritchi',
  'croods',
  'vector',
  'shield',
  'fandom',
  'direct',
  'dreams',
  'butler',
  'dumpty',
  'google',
  'beauty',
  'entity',
  'winger',
  'deadly',
  'foobar',
  'disney',
  'master',
  'flower',
  'unreal',
  'doodle',
  'trivia',
  'medals',
  'arcaid',
  'poster',
  'bergen',
  'python',
  'blinky',
  'smidge',
  'africa',
  'series',
  'netapp',
  'monkey',
  'tables',
  'previz',
  'rescue',
  'techno',
  'palace',
  'branch',
  'models',
  'crowds',
  // 7-letter words
  'peabody',
  'tailung',
  'dragons',
  'musical',
  'perrito',
  'variant',
  // April fools 2023 (on Fri 3/31 - Sun)
  'beeeeeeees',
  'binding',
  'everest',
  'skipper',
  'diamond',
  'princed',
  'defaria',
  'mystery',
  'narrate',
  'sherman',
  'beeeeee',
  'giraffe',
  'chuckle',
  'firefox',
  'rameses',
  'penguin',
  'checkin',
  'rigging',
  'chicken',
  'painter',
  'moonboy',
  'masters',
  'captain',
  'monster',
  'element',
  'stewart',
  'vikings',
  'chinese',
  'furious',
  'burnish',
  'private',
  'ruffnut',
  'gorilla',
  'smekday',
  'tigress',
  'cupcake',
  'moonray',
  'fairies',
  'gristle',
  'roxanne',
  'teenage',
  'gronckle',
  'maurice',
  'grammer',
  'badguys',
  'houdini',
  'noodles',
  'theater',
  'sandman',
  'krakens',
  'bridget',
  'tighten',
  'tuffnut',
  'shrekle',
  'warrior',
  'jeffrey',
  'bergens',
  'bandits',
  'mermaid',
  'magical',
  'babbles',
  'wayback',
  'animals',
  'villain',
  'wallace',
  'gillman',
  'piranha',
  'grimmel',
]